body {
    margin: 0;
    font-family: sans-serif;
    width: 100vw;
    height: 100vh;
    overflow: hidden;
}

#map {
    width: 100vw;
    height: 100vh;
}

h1, h2, h3, h4, h5, h6 {
    margin: 0;
    padding: 0
}

.graphics {
    list-style: none;
}

.graphics .graphic-item {
    display: inline;
    margin: 2px;
}

.tooltip {
    pointer-events: none;
    position: absolute;
    z-index: 9;
    font-size: 12px;
    padding: 8px;
    background: #000;
    color: #fff;
    min-width: 160px;
    max-height: 240px;
    overflow-y: hidden;
}

.tooltip.interactive {
    pointer-events: all;
    /*background: #fcfcfc;*/
    background: #000;
    /*color: #444;*/
    color: #fff;
    overflow-y: auto;
}

.control-panel {
    position: absolute;
    top: 0;
    right: 0;
    width: 350px;
    background: #fff;
    padding: 12px 24px;
    margin: 0;
    font-size: 13px;
    line-height: 2;
    color: #6b6b76;
    height: 50%;
    outline: none;
    overflow: scroll;
    z-index: 3;
}

label {
    display: block;
}

input {
    margin-left: 20px;
}
